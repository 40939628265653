// @src/hooks/useVideoPlayer.js

import { useState, useEffect } from "react";

const PLAYER_STATE = {
  UNSTARTED: -1,
  ENDED: 0,
  PLAYING: 1,
  PAUSED: 2,
  BUFFERING: 3,
  CUED: 5,
}

const useVideoPlayer = (videoElement) => {
  const [duration, setDuration] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [progress, setProgress] = useState(0)
  const [isMuted, setIsMuted] = useState(false)
  const [playerState, setPlayerState] = useState({
    isPlaying: false,
    progress: 0,
    speed: 1,
    isMuted: false,
    startAt: 0,
  });

  useEffect(() => {
    if (isPlaying) {
      const interval = setInterval(async () => {
        const currentTime = await videoElement?.current?.internalPlayer.getCurrentTime();
        const duration = await videoElement?.current?.internalPlayer.getDuration()
        const progress = (currentTime / duration) * 100;
        setDuration(duration)
        setProgress(progress)
        setPlayerState({
          ...playerState,
          progress,
        });
      }, 1000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [isPlaying])

  const togglePlay = (startAt = 0) => {
    setPlayerState({
      ...playerState,
      startAt,
      isPlaying: !playerState.isPlaying,
    });
  };

  useEffect(() => {
    if (videoElement?.current) {
      playerState.isPlaying
        ? playerState.startAt > 0 ? videoElement?.current?.internalPlayer.playVideoAt(playerState.startAt) : videoElement?.current?.internalPlayer.playVideo()
        : videoElement?.current?.internalPlayer.pauseVideo();
    }
    // eslint-disable-next-line
  }, [playerState.isPlaying, videoElement]);

  const handleOnTimeUpdate = (event) => {
    setIsPlaying(event?.data === PLAYER_STATE.PLAYING)
    if (event?.data === PLAYER_STATE.ENDED) {
      //init
      videoElement?.current?.internalPlayer.stopVideo()
      setProgress(0)
      setPlayerState({
        ...playerState,
        isPlaying: !playerState.isPlaying,
        startAt: 0,
      });
    }
  };

  const handleVideoProgress = (event) => {
    const manualChange = Number(event.target.value);
    videoElement?.current?.internalPlayer.seekTo((duration / 100) * manualChange);
    setProgress(manualChange)
  };


  const toggleMute = () => {
    setIsMuted(!isMuted)
  };

  useEffect(() => {
    if (videoElement?.current) {
      isMuted
        ? (videoElement?.current?.internalPlayer.mute())
        : (videoElement?.current?.internalPlayer.unMute());
    }
  }, [isMuted, videoElement]);

  return {
    playerState: {
      ...playerState,
      progress,
      isMuted,
    },
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
    toggleMute,
  };
};

export default useVideoPlayer;