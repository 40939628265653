import React, { useRef, useState } from 'react';
import YouTube from 'react-youtube';
import "./App.css";
import useWindowDimensions from "./hooks/useWindowDimensions";
import useYoutubeVideoPlayer from "./hooks/useYoutubeVideoPlayer";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause, faVolumeHigh, faVolumeXmark, faExpand } from '@fortawesome/free-solid-svg-icons'
import { FullScreen, useFullScreenHandle } from "react-full-screen";


library.add(faPlay, faPause, faVolumeHigh, faVolumeXmark, faExpand);
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const v = urlParams.get('v')
function App() {
  const { width, height } = useWindowDimensions();
  const playerRef = useRef(null);
  const maximizableElement = useRef(null);
  const {
    playerState,
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
    toggleMute,
  } = useYoutubeVideoPlayer(playerRef);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const handle = useFullScreenHandle();

  const handleFullscreen = () => {
    if (isFullscreen) {
      handle.exit();
    } else {
      handle.enter();
    }
    setIsFullscreen(!isFullscreen);
  }

  const onScreenUpdate = (state, handle) => {
    console.log('onScreenUpdate', state, handle);
    setIsFullscreen(state);
  }

  return (
    <div className="container">
      <FullScreen handle={handle} onChange={onScreenUpdate}>
        <div className="video-wrapper" ref={maximizableElement} >
          <div onClick={togglePlay}>
            <div style={{ pointerEvents: 'none', background: "black", width: width, height: height }} >
              <YouTube
                videoId={v}
                opts={{
                  width,
                  height,
                  playerVars: {
                    controls: 0,
                    rel: 0,
                    showInfo: 0,
                    hd: 1,
                  },
                  host: "https://www.youtube-nocookie.com"
                }}
                ref={playerRef}
                onStateChange={handleOnTimeUpdate}
              />
            </div>
          </div>
          <div className="controls">

            <div className="actions">
              <button onClick={togglePlay}>
                {!playerState.isPlaying ? (
                  <FontAwesomeIcon icon="fa-solid fa-play" />
                ) : (
                  <FontAwesomeIcon icon="fa-solid fa-pause" />
                )}
              </button>
            </div>
            <input
              type="range"
              min="0"
              max="100"
              value={playerState.progress}
              onChange={(e) => handleVideoProgress(e)}
            />
            <button className="mute-btn" onClick={toggleMute}>
              {!playerState.isMuted ? (
                <FontAwesomeIcon icon="fa-solid fa-volume-high" />
              ) : (
                <FontAwesomeIcon icon="fa-solid fa-volume-xmark" />
              )}
            </button>
            <button className="mute-btn" onClick={() => handleFullscreen()}>
              <FontAwesomeIcon icon="fa-solid fa-expand" />
            </button>
          </div>
        </div>
      </FullScreen>
    </div>
  )
}

export default App;
